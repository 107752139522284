'use strict'

resizeFlg = {}
resizeFlg.pc = true
resizeFlg.sp = true
firstView = ''

pcCaseSlideElement = ''
spCaseSlideElement1 = ''
spCaseSlideElement2 = ''

spCaseSlide = ()->
  console.log('index:SP')

  # 症例スライド
  spCaseSlideElement1 = new Swiper('.slide1.p-case__slide', {
    speed: 2000
    autoplay: {
      delay: 7000,
      disableOnInteraction: false,
    },
    loop: true,
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  })

  spCaseSlideElement2 = new Swiper('.slide2.p-case__slide', {
    speed: 2000
    autoplay: {
      delay: 7000,
      disableOnInteraction: false,
    },
    # loop: true,
    effect: 'fade'
    fadeEffect: {
      crossFade: true
    },
  })

  $('.p-case .slide1 .js-slide__arrow').on('click', ()->
    spCaseSlideElement2.slideTo(spCaseSlideElement1.realIndex, 2000)
  )

  $('.p-case .slide1 .swiper-pagination-bullet').on('click', ()->
    spCaseSlideElement2.slideTo($(this).index(), 2000)
  )

pcCaseSlide = ()->
  console.log('index:PC')

  caseCount = $('.p-case__tab__item').length
  i = if $('.p-case__tab__item.is-active').index() is 0 then 0 else $('.p-case__tab__item.is-active').index() + 1

  pcCaseSlideElement = setInterval(
    ()->
      $('.p-case__tab__item').eq(i).find('a').trigger('click')

      i++

      if i > caseCount - 1
        i = 0
  , 3000)

if window.matchMedia('(max-width:750px)').matches
  # SP
  firstView = 'SP'

  $('.mainimg__main').slick({
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 900,
    centerMode: true,
    variableWidth: true,
    swipeToSlide: true,
    dots: true
  })

  spCaseSlide()
else
  # PC
  firstView = 'PC'

  $('.mainimg__main').slick({
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 900,
    arrows: true,
    asNavFor: '.mainimg__sub',
  })

  $('.mainimg__sub').slick({
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 900,
    slidesToShow: 6,
    centerMode: true,
    asNavFor: '.mainimg__main',
    focusOnSelect: true,
  })

  pcCaseSlide()

$(window).on('resize',
  ()->
    if window.matchMedia('(max-width:750px)').matches
      if firstView is 'PC' and resizeFlg.sp is true or
      firstView is false and resizeFlg.sp is true
        # PC から SP へ

        clearInterval(pcCaseSlideElement)

        spCaseSlide()

        resizeFlg.pc = true
        resizeFlg.sp = false
        firstView = false
    else
      if firstView is 'SP' and resizeFlg.pc is true or
      firstView is false and resizeFlg.pc is true
        # SP から PC へ

        spCaseSlideElement1.destroy()

        spCaseSlideElement2.destroy()

        pcCaseSlide()

        resizeFlg.pc = false
        resizeFlg.sp = true
        firstView = false
)

#headerSlide = new Swiper('.p-header__hero', {
#  speed: 2000
#  autoplay: {
#    delay: 7000,
#    disableOnInteraction: false,
#  },
#  loop: true,
#  pagination: {
#    el: '.swiper-pagination',
#    clickable: true
#  },
#  navigation: {
#    nextEl: '.swiper-button-next',
#    prevEl: '.swiper-button-prev',
#  },
#})